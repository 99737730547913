import React from "react";

import DashboardLayout from "./layouts/Dashboard";
import AssignedRule from "pages/Settings/ruleGroup/AssignedRule";
import ActionTrackerMainCard from "pages/requeststatement/components/ActionTrackerMainCard";
import AttachmentsAndComments from "pages/requeststatement/components/AttachmentsAndComments";
import RecoAndDocumentMainCard from "pages/requeststatement/components/ActionTracker/RecoAndDocumentMainCard";
import {
  ACTION_RECO_SUMMARY,
  ADD_NEW_PARTY,
  ADD_RULE,
  ASSING_RULES_TO_COMPANY,
  ATTACHMENTS_AND_COMMENTS,
  AUTOMATION,
  COMPANY_RECO_SETTINGS,
  COMPANY_STATEMENT,
  CONFIRMATION,
  CONST_DATATABLE,
  CONVERSATION_LOGS,
  CREATE_REPORTS,
  DIRECT_RECO_STATEMENT,
  DIRECT_RECO_TABLE,
  DOWNLOAD,
  EDIT_SETTINGS,
  ERROR,
  IMPORT_DATATABLE,
  INTERANL_TEAM,
  LOGGED_OUT,
  MARK_AS_RESPOND,
  MATCHED_DATA,
  MODAL,
  NEW,
  OTHER_DETAILS,
  PARTY_MASTER_DATA,
  PARTY_MASTER_HOME,
  PARTY_RECO_SUMMARY,
  PDF,
  PROGRESS,
  RECO_SETTINGS,
  RECO_SUMMARY,
  REPORTS,
  REQUEST_STATEMENT,
  REQUEST_STATEMENT_MAIN,
  RULE_GROUP,
  SCHEDULER,
  SELECT_PARTY,
  SETTINGS,
  SFTP,
  STATISTICS,
  THANKYOU,
  TOKEN_EXPIRE,
  UNMATCHED_DATA,
  UPLOAD_FILE,
  VERIFY,
  VERIFY_DONE,
  VIEW_ACTION_TRACKER_TABLE,
  VIEW_UPLOAD_SCREEN,
  WORKFLOW_SETTINGS,
} from "utils/constant";
import MainCardSftp from "pages/StatementTransformation/MainCardSftp";
import WorkFlowSettings from "pages/WorkFlowSettings/WorkFlowSettings";

//Main Routes
const ImportDataTable = React.lazy(() =>
  import("pages/partymaster/components/ImportDataTable")
);
const Partymasterhome = React.lazy(() =>
  import("pages/partymaster/PartyMasterHome")
);
const UploadFile = React.lazy(() =>
  import("pages/partymaster/components/UploadFile")
);
const AddNewParty = React.lazy(() =>
  import("pages/partymaster/components/AddNewParty")
);
const InternalTeam = React.lazy(() =>
  import("pages/partymaster/components/InternalTeam")
);
const OtherDetails = React.lazy(() =>
  import("pages/partymaster/components/OtherDetails")
);
const PartyStatementMain = React.lazy(() =>
  import("pages/newpartyrequest/components/PartyStatementMain")
);
const PreviewModal = React.lazy(() =>
  import("pages/newpartyrequest/components/PreviewModal")
);
const DownloadprogressBar = React.lazy(() =>
  import("pages/newpartyrequest/components/ProgressBar")
);
const VerifyData = React.lazy(() =>
  import("pages/newpartyrequest/components/VerifyData")
);
const SuccessVerityData = React.lazy(() =>
  import("pages/newpartyrequest/components/SuccessVerifyData")
);
const PartyMasterData = React.lazy(() =>
  import("pages/partymaster/components/PartyMasterData")
);
const PartyUpload = React.lazy(() => import("pages/confirmation"));
const SelectParty = React.lazy(() =>
  import("pages/directReconciliation/SelectParty")
);
const EditSettings = React.lazy(() =>
  import("pages/directReconciliation/EditSettings")
);
const StatementCards = React.lazy(() =>
  import("pages/directReconciliation/StatementCards")
);
const StatementRequest = React.lazy(() =>
  import("pages/requeststatement/components/StatementRequest")
);
const MainCard = React.lazy(() =>
  import("pages/requeststatement/components/MainCard")
);
const DirectRecoTable = React.lazy(() =>
  import("pages/directReconciliation/DirectRecoTable")
);
const ViewUploadScreen = React.lazy(() =>
  import("pages/requeststatement/pages/ViewUploadScreen")
);
const RecoSummary = React.lazy(() =>
  import("pages/requeststatement/pages/RecoSummary")
);
const MatchedMainCard = React.lazy(() =>
  import("pages/requeststatement/components/MatchedMainCard")
);
const UnMatchedMainCard = React.lazy(() =>
  import("pages/requeststatement/components/UnMatchedMainCard")
);
const PartyRecoSummary = React.lazy(() =>
  import("pages/partyrecosummary/PartyRecoSummary")
);
const MatchedPartyCard = React.lazy(() =>
  import("pages/partyrecosummary/components/MatchedPartyCard")
);
const LogOut = React.lazy(() => import("LogOut"));
const TokenExpire = React.lazy(() => import("TokenExpire"));
const UnMatchedPartyCard = React.lazy(() =>
  import("pages/partyrecosummary/components/UnMatchedPartyCard")
);
const DownloadPage = React.lazy(() =>
  import("pages/partyrecosummary/DownloadPage")
);
const DownLoadPdf = React.lazy(() =>
  import("pages/partyrecosummary/DownLoadPdf")
);
const Page404 = React.lazy(() => import("pages/Page404"));
const SomethingWentWrong = React.lazy(() => import("pages/SomethingWentWrong"));
const ScheduleRequest = React.lazy(() =>
  import("pages/Automation/Scheduler/ScheduleRequest")
);
const NewScheduler = React.lazy(() =>
  import("pages/Automation/Scheduler/NewScheduler")
);
const AllRuleGroups = React.lazy(() =>
  import("pages/Settings/ruleGroup/AllRuleGroups")
);
const AddRule = React.lazy(() => import("pages/Settings/ruleGroup/AddRule"));
const RuleGroup = React.lazy(() =>
  import("pages/Settings/ruleGroup/RuleGroup")
);

const CompanyRecoSettings = React.lazy(() =>
  import("pages/companySettings/CompanyRecoSettings")
);

const ConversationLogs = React.lazy(() =>
  import("pages/requeststatement/pages/ConversationLogs.jsx")
);

const MainCardCombineReport = React.lazy(() =>
  import("pages/CombineReport/MainCardCombineReport")
);

const CreateReportPage = React.lazy(() =>
  import("pages/CombineReport/CreateReportPage")
);

const CombineReportStatistics = React.lazy(() =>
  import("pages/CombineReport/CombineReportStatistics")
);

const routes = [
  {
    path: "/*",
    element: (
      <React.Suspense fallback="Loading...">
        <Page404 />
      </React.Suspense>
    ),
  },
  {
    path: `/${ERROR}`,
    element: (
      <React.Suspense fallback="Loading...">
        <SomethingWentWrong />
      </React.Suspense>
    ),
  },
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: `/${PARTY_MASTER_DATA}/${IMPORT_DATATABLE}`,
        element: (
          <React.Suspense fallback="Loading...">
            <ImportDataTable />
          </React.Suspense>
        ),
      },
      {
        path: `/${PARTY_MASTER_DATA}`,
        element: (
          <React.Suspense fallback="Loading...">
            <PartyMasterData />
          </React.Suspense>
        ),
      },
      {
        path: `/${PARTY_MASTER_HOME}`,
        element: (
          <React.Suspense fallback="Loading...">
            <Partymasterhome />
          </React.Suspense>
        ),
      },
      {
        path: `/${UPLOAD_FILE}`,
        element: (
          <React.Suspense fallback="Loading...">
            <UploadFile />
          </React.Suspense>
        ),
      },
      {
        path: `/${PARTY_MASTER_DATA}/${ADD_NEW_PARTY}`,
        element: (
          <React.Suspense fallback="Loading...">
            <AddNewParty />
          </React.Suspense>
        ),
      },
      {
        path: `/${PARTY_MASTER_DATA}/${ADD_NEW_PARTY}`,
        element: (
          <React.Suspense fallback="Loading...">
            <AddNewParty />
          </React.Suspense>
        ),
      },
      {
        path: `/${INTERANL_TEAM}`,
        element: (
          <React.Suspense fallback="Loading...">
            <InternalTeam />
          </React.Suspense>
        ),
      },
      {
        path: `/${OTHER_DETAILS}`,
        element: (
          <React.Suspense fallback="Loading...">
            <OtherDetails />
          </React.Suspense>
        ),
      },
      {
        path: `/${CONST_DATATABLE}`,
        element: (
          <React.Suspense fallback="Loading...">
            <constDataTable />
          </React.Suspense>
        ),
      },
      {
        path: `/${COMPANY_STATEMENT}`,
        element: (
          <React.Suspense fallback="Loading...">
            <PartyStatementMain />
          </React.Suspense>
        ),
      },
      {
        path: `/${MODAL}`,
        element: (
          <React.Suspense fallback="Loading...">
            <PreviewModal />
          </React.Suspense>
        ),
      },
      {
        path: `/${VERIFY}`,
        element: (
          <React.Suspense fallback="Loading...">
            <VerifyData />
          </React.Suspense>
        ),
      },
      {
        path: `/${VERIFY_DONE}`,
        element: (
          <React.Suspense fallback="Loading...">
            <SuccessVerityData />
          </React.Suspense>
        ),
      },
      {
        path: `/${PROGRESS}`,
        element: (
          <React.Suspense fallback="Loading...">
            <DownloadprogressBar />
          </React.Suspense>
        ),
      },
      {
        path: `/${DIRECT_RECO_TABLE}/${SELECT_PARTY}`,
        element: (
          <React.Suspense fallback="Loading...">
            <SelectParty />
          </React.Suspense>
        ),
      },
      {
        path: `/${DIRECT_RECO_TABLE}/${EDIT_SETTINGS}`,
        element: (
          <React.Suspense fallback="Loading...">
            <EditSettings />
          </React.Suspense>
        ),
      },
      {
        path: `/${REQUEST_STATEMENT}/${EDIT_SETTINGS}`,
        element: (
          <React.Suspense fallback="Loading...">
            <EditSettings />
          </React.Suspense>
        ),
      },
      {
        path: `/${DIRECT_RECO_TABLE}`,
        element: (
          <React.Suspense fallback="Loading...">
            <DirectRecoTable />
          </React.Suspense>
        ),
      },
      {
        path: `/${DIRECT_RECO_TABLE}/${RECO_SUMMARY}`,
        element: (
          <React.Suspense fallback="Loading...">
            <RecoSummary />
          </React.Suspense>
        ),
      },
      {
        path: `/${DIRECT_RECO_TABLE}/${DIRECT_RECO_STATEMENT}`,
        element: (
          <React.Suspense fallback="Loading...">
            <StatementCards />
          </React.Suspense>
        ),
      },
      {
        path: `/${REQUEST_STATEMENT}`,
        element: (
          <React.Suspense fallback="Loading...">
            <StatementRequest />
          </React.Suspense>
        ),
      },
      {
        path: `/${REQUEST_STATEMENT}/${REQUEST_STATEMENT_MAIN}`,
        element: (
          <React.Suspense fallback="Loading...">
            <MainCard />
          </React.Suspense>
        ),
      },
      {
        path: `/${DIRECT_RECO_TABLE}/${VIEW_UPLOAD_SCREEN}`,
        element: (
          <React.Suspense fallback="Loading...">
            <ViewUploadScreen />
          </React.Suspense>
        ),
      },
      {
        path: `/${REQUEST_STATEMENT}/${VIEW_UPLOAD_SCREEN}`,
        element: (
          <React.Suspense fallback="Loading...">
            <ViewUploadScreen />
          </React.Suspense>
        ),
      },
      {
        path: `/${REQUEST_STATEMENT}/${REQUEST_STATEMENT_MAIN}/${RECO_SUMMARY}`,
        element: (
          <React.Suspense fallback="Loading...">
            <RecoSummary />
          </React.Suspense>
        ),
      },
      {
        path: `/${DIRECT_RECO_TABLE}/${MATCHED_DATA}`,
        element: (
          <React.Suspense fallback="Loading...">
            <MatchedMainCard />
          </React.Suspense>
        ),
      },
      {
        path: `/${DIRECT_RECO_TABLE}/${UNMATCHED_DATA}`,
        element: (
          <React.Suspense fallback="Loading...">
            <UnMatchedMainCard />
          </React.Suspense>
        ),
      },
      {
        path: `/${REQUEST_STATEMENT}/${MATCHED_DATA}`,
        element: (
          <React.Suspense fallback="Loading...">
            <MatchedMainCard />
          </React.Suspense>
        ),
      },
      {
        path: `/${REQUEST_STATEMENT}/${UNMATCHED_DATA}`,
        element: (
          <React.Suspense fallback="Loading...">
            <UnMatchedMainCard />
          </React.Suspense>
        ),
      },

      {
        path: `/${AUTOMATION}/${SCHEDULER}`,
        element: (
          <React.Suspense fallback="Loading...">
            <ScheduleRequest />
          </React.Suspense>
        ),
      },
      {
        path: `/${AUTOMATION}/${SCHEDULER}/${NEW}`,
        element: (
          <React.Suspense fallback="Loading...">
            <NewScheduler />
          </React.Suspense>
        ),
      },
      {
        path: "/settings",
        element: (
          <React.Suspense fallback="Loading...">
            <AllRuleGroups />
          </React.Suspense>
        ),
      },
      {
        path: `/${SETTINGS}/${RULE_GROUP}`,
        element: (
          <React.Suspense fallback="Loading...">
            <RuleGroup />
          </React.Suspense>
        ),
      },
      {
        path: `/${SETTINGS}/${ADD_RULE}`,
        element: (
          <React.Suspense fallback="Loading...">
            <AddRule />
          </React.Suspense>
        ),
      },
      {
        path: `/${SETTINGS}/${ASSING_RULES_TO_COMPANY}`,
        element: (
          <React.Suspense fallback="Loading...">
            <AssignedRule />
          </React.Suspense>
        ),
      },
      {
        path: `/${REQUEST_STATEMENT}/${VIEW_ACTION_TRACKER_TABLE}`,
        element: (
          <React.Suspense fallback="Loading...">
            <ActionTrackerMainCard />
          </React.Suspense>
        ),
      },
      {
        path: `/${REQUEST_STATEMENT}/${ATTACHMENTS_AND_COMMENTS}`,
        element: (
          <React.Suspense fallback="Loading...">
            <AttachmentsAndComments />
          </React.Suspense>
        ),
      },
      {
        path: `/${ACTION_RECO_SUMMARY}`,
        element: (
          <React.Suspense fallback="Loading...">
            <RecoAndDocumentMainCard />
          </React.Suspense>
        ),
      },
      {
        path: `/${RECO_SETTINGS}/${COMPANY_RECO_SETTINGS}`,
        element: (
          <React.Suspense fallback="Loading...">
            <CompanyRecoSettings />
          </React.Suspense>
        ),
      },
      {
        path: `/${WORKFLOW_SETTINGS}`,
        element: (
          <React.Suspense fallback="Loading...">
            <WorkFlowSettings />
          </React.Suspense>
        ),
      },
      {
        path: `/${REQUEST_STATEMENT}/${CONVERSATION_LOGS}`,
        element: (
          <React.Suspense fallback="Loading...">
            <ConversationLogs />
          </React.Suspense>
        ),
      },
      {
        path: `/${SFTP}`,
        element: (
          <React.Suspense fallback="Loading...">
            <MainCardSftp />
          </React.Suspense>
        ),
      },
      {
        path: `/${REPORTS}`,
        element: (
          <React.Suspense fallback="Loading...">
            <MainCardCombineReport />
          </React.Suspense>
        ),
      },
      {
        path: `/${REPORTS}/${CREATE_REPORTS}`,
        element: (
          <React.Suspense fallback="Loading...">
            <CreateReportPage />
          </React.Suspense>
        ),
      },
      {
        path: `/${REPORTS}/${STATISTICS}`,
        element: (
          <React.Suspense fallback="Loading...">
            <CombineReportStatistics />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: `/${CONFIRMATION}`,
    element: (
      <React.Suspense fallback="Loading...">
        <PartyUpload header={true} isParty={true} />
      </React.Suspense>
    ),
  },
  {
    path: `/${CONFIRMATION}/${THANKYOU}`,
    element: (
      <React.Suspense fallback="Loading...">
        <PartyUpload header={true} isParty={true} />
      </React.Suspense>
    ),
  },
  {
    path: `/${REQUEST_STATEMENT}/${REQUEST_STATEMENT_MAIN}/${MARK_AS_RESPOND}`,
    element: (
      <React.Suspense fallback="Loading...">
        <PartyUpload header={false} isParty={false} />
      </React.Suspense>
    ),
  },
  {
    path: `/${PARTY_RECO_SUMMARY}`,
    element: (
      <React.Suspense fallback="Loading...">
        <PartyRecoSummary />
      </React.Suspense>
    ),
  },
  {
    path: `/${PARTY_RECO_SUMMARY}/${DOWNLOAD}`,
    element: (
      <React.Suspense fallback="Loading...">
        <DownloadPage />
      </React.Suspense>
    ),
  },
  {
    path: `/${PARTY_RECO_SUMMARY}/${DOWNLOAD}/${PDF}`,
    element: (
      <React.Suspense fallback="Loading...">
        <DownLoadPdf />
      </React.Suspense>
    ),
  },
  {
    path: `/${PARTY_RECO_SUMMARY}/${MATCHED_DATA}`,
    element: (
      <React.Suspense fallback="Loading...">
        <MatchedPartyCard />
      </React.Suspense>
    ),
  },
  {
    path: `/${PARTY_RECO_SUMMARY}/${UNMATCHED_DATA}`,
    element: (
      <React.Suspense fallback="Loading...">
        <UnMatchedPartyCard />
      </React.Suspense>
    ),
  },

  {
    path: `/${LOGGED_OUT}`,
    element: (
      <React.Suspense fallback="Loading...">
        <LogOut />
      </React.Suspense>
    ),
  },
  {
    path: `/${TOKEN_EXPIRE}`,
    element: (
      <React.Suspense fallback="Loading...">
        <TokenExpire />
      </React.Suspense>
    ),
  },
];

export default routes;
