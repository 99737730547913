const initialValues = {
  sftpList: [],
  sftpSearchedList: [],
  sftpSearchedListSingleParty: [],
  sftpGrirListSingleParty: [],
  error: null,
  sftp: false,
  company_code: "",
  isApi: false,
  pan: "",
  companyName: "",
  companyId: "",
  isParentCompany: false,
};

export default initialValues;
