const initialValues = {
  error: null,
  reportTableData: [],
  selectedRecos: [],
  selected: [],
  reportTitle: "",
  reportData: {},
  id: "",
  selectedRecoIds: [],
};

export default initialValues;
