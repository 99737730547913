import { TableCell, Tooltip, tooltipClasses } from "@mui/material";
import React from "react";
import styled from "styled-components/macro";

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    slotProps={{
      popper: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -12],
            },
          },
        ],
      },
    }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
const CTableCell = ({
  isChecked,
  isParty,
  isStatus = false,
  data,
  textAlign = "left",
  ...props
}) => {
  return (
    <TableCell
      sx={{
        fontSize: "14px",
        fontFamily: "ekmukta",
        borderBottom: isChecked ? "0.5px solid #0C6DFF" : "",
        borderTop: isChecked ? "0.5px solid #0C6DFF" : "",
        paddingBlock: isStatus ? "6px" : "0px",
        paddingRight: "10px",
        backgroundColor: isParty ? "#D5FFBC" : "",
        borderRadius: isStatus ? "5px" : "0px",
        textAlign: textAlign,
      }}
      {...props}
    >
      <CustomTooltip title={data} arrow placement="top">
        <span
          style={{
            background: isStatus ? "#FA8F8F" : "",
            paddingInline: isStatus ? "6px" : "",
            borderRadius: "8px",
          }}
        >
          {data?.length >= 25
            ? data
                ?.substring(0, 25)
                ?.replace(/ /g, "\u00a0")
                ?.replace(/-/g, "\u2011") + "..."
            : data?.replace(/ /g, "\u00a0")?.replace(/-/g, "\u2011")}
        </span>
      </CustomTooltip>
    </TableCell>
  );
};

export default CTableCell;
