import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DummyButton from "components/button/DummyButton";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getCompanySettings,
  updateCompanySettings,
} from "store/companysettings/slice";
import { getWorkflowSttingTypes } from "store/workFlowSettings/slice";

const saveBtnStyle = {
  width: "130px",
  height: "35px",
  background: "#005724",
  borderRadius: "5px",
};

const cancelBtnStyle = {
  width: "130px",
  height: "35px",
  background: "#FFFFFF",
  color: " #005724",
  border: "1px solid #FF9A01",
  boxSizing: " border-box",
  borderRadius: "5px",
};

const optionReportTypes = {
  entire_reconciliation_report: "Entire Reconciliation Report",
  party_details_only: "Party Details Only",
  display_both: "Display Both Options",
};

const WorkFlowSettings = () => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [selectedReportType, setSelectedReportType] = useState({
    id: "",
    value: "Select",
  });
  const [disabled, setDisabled] = useState(false);
  const [initialSettingValues, setInitialSettingValues] = useState({}); //for initial values
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(getCompanySettings()).then((res) => {
      setInitialSettingValues(res?.payload);
      if (res?.payload?.workflowOptions) {
        setSelectedReportType({
          id: res?.payload?.workflowOptions,
          value: optionReportTypes[res?.payload?.workflowOptions],
        });
      }
    });
    dispatch(getWorkflowSttingTypes()).then((res) => {
      setOptions(res.payload);
    });
    // eslint-disable-next-line
  }, []);

  const selectStyle = {
    width: "30%",
    background: "#fff",
    "& .MuiSvgIcon-root": {
      color: "#000",
    },
  };

  const handleSave = () => {
    // API call for update data/settings
    const data = {
      ...initialSettingValues,
      workflowOptions: selectedReportType.id,
    };
    dispatch(
      updateCompanySettings({
        data,
        settingId: initialSettingValues?.settingId,
      })
    ).then((res) => {
      setDisabled(false);
      setInitialSettingValues(res?.payload);
      enqueueSnackbar("Workflow setting saved successfully", {
        variant: "success",
        style: {
          whiteSpace: "pre-line",
          fontFamily: "ekmukta-bold",
        },
      });
    });
  };
  return (
    <>
      <Box
        px={"24px"}
        py={"16px"}
        bgcolor={"#FFF"}
        borderRadius={"7.75px"}
        boxShadow="0px 2px 5px 0px #0000000D"
        mt={"-30px"}
        mr={"10px"}
      >
        <Typography fontFamily={"ekmukta-bold"} fontSize={"20px"} mb={"15px"}>
          Workflow Settings{" "}
        </Typography>
        <Stack bgcolor={"#F3F3F3"} borderRadius={"7.75px"} p={"18px"}>
          <Stack
            py={"18px"}
            px={"24px"}
            border={"2px solid #A0A0A0"}
            borderRadius={"7.75px"}
          >
            <Typography fontFamily={"ekmukta"} fontSize={"12px"}>
              Choose the type of report to be displayed on Request signoff
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <Autocomplete
              size="small"
              options={options ?? []}
              disableClearable
              getOptionLabel={(option) => option.value}
              value={selectedReportType}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select" />
              )}
              onChange={(event, newValue) => {
                setSelectedReportType(newValue);
              }}
              sx={selectStyle}
            />
          </Stack>
        </Stack>
        <Grid container spacing={4}>
          <Grid item xs={12} md={10}></Grid>

          <Grid item xs={12} md={2}>
            {/*  button section */}
            <Box
              sx={{
                display: "flex",
                textAlign: "right",
                gap: "14px",
                mt: "10px",
              }}
            >
              {/* cancel button */}
              <Button variant="contained" style={cancelBtnStyle}>
                Cancel
              </Button>
              {/* save button */}
              {disabled ? (
                <DummyButton />
              ) : (
                <Button
                  variant="contained"
                  style={saveBtnStyle}
                  onClick={handleSave}
                >
                  Save
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default WorkFlowSettings;
