import React, { useRef } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";

import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";

import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";

import "../src/assets/styles/common.css";
import axiosApi from "helper/axiosApi";
import axiosPartyApi from "helper/axiosPartyApi";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountOfTableRecords,
  getRecoRecordById,
  setActiveValue,
  setIsMiniSidebar,
  setModule,
  setPartyCode,
  setRecoId,
  setRecoIdofRow,
  setRecoName,
  setRequestType,
} from "store/requeststatement/slice";
import { setActiveTab } from "store/newparty/slice";
import { getAllCommentsParty } from "store/partyrecosummary/slice";
import "./index.css";
import "./assets/styles/allpopups.css";
import "./assets/styles/clearreconciliation.css";
import "./assets/styles/common.css";
import "./assets/styles/companystatementdata.css";
import "./assets/styles/configure.css";
import "./assets/styles/confirmation.css";
import "./assets/styles/contactpersontable.css";
import "./assets/styles/directreco.css";
import "./assets/styles/directrecotable.css";
import "./assets/styles/importdatatable.css";
import "./assets/styles/matched.css";
import "./assets/styles/menu.css";
import "./assets/styles/modalbox.css";
import "./assets/styles/modifyconfigmodal.css";
import "./assets/styles/partydetails.css";
import "./assets/styles/partymasterdatatable.css";
import "./assets/styles/partymasterhome.css";
import "./assets/styles/partymatched.css";
import "./assets/styles/partyrecosummary.css";
import "./assets/styles/partysignofftable.css";
import "./assets/styles/perfect-scrollbar.css";
import "./assets/styles/previewmodal.css";
import "./assets/styles/progressbar.css";
import "./assets/styles/recosummary.css";
import "./assets/styles/reviewdone.css";
import "./assets/styles/selectparty.css";
import "./assets/styles/selectpartytable.css";
import "./assets/styles/statementrequest.css";
import "./assets/styles/statistics.css";
import "./assets/styles/stepper.css";
import "./assets/styles/stopremindermodal.css";
import "./assets/styles/uploadledger.css";
import "./assets/styles/errorpage.css";
import "./assets/styles/settingsrule.css";
import {
  Box,
  Modal,
  Stack,
  Typography,
  Snackbar,
  Alert,
  Button,
} from "@mui/material";
import { setErrorSnackbar, setIsTokenExpire } from "store/masterdata/slice";
import { ClockedIcon, WhiteCross } from "components/AllSvg";
import { reset } from "store/confirmation/slice";
import { SnackbarProvider, MaterialDesignContent } from "notistack";
import { makeStyles, styled } from "@mui/styles";
import {
  AUTOMATION,
  COMPANY_RECO_SETTINGS,
  COMPANY_STATEMENT,
  CONFIRMATION,
  DIRECT_RECO_TABLE,
  IS_MULTI_TAB_SUPPORT,
  PARTY_MASTER_DATA,
  PARTY_RECO_SUMMARY,
  RECO_SETTINGS,
  REQUEST_STATEMENT,
  REQUEST_STATEMENT_MAIN,
  SCHEDULER,
  SFTP,
} from "utils/constant";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "550px",
  bgcolor: "#FFF",
  borderRadius: "8px",
  boxShadow: 8,
  p: 8,
};

function App() {
  const content = useRoutes(routes);

  const { theme } = useTheme();
  const { isTokenExpire, errorSnackbar, errorMsg } = useSelector(
    (state) => state.masterData
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const v1URL = process.env.REACT_APP_V1_URL;
  const v2URL = process.env.REACT_APP_BASE_URL;
  React.useEffect(() => {
    var url = window.location;
    var token = new URLSearchParams(url.search).get("token");
    var ptoken = new URLSearchParams(url.search).get("ptoken");
    var partyToken = IS_MULTI_TAB_SUPPORT ? sessionStorage.getItem("partyToken") : localStorage.getItem("partyToken");
    var temptoken = localStorage.getItem("tempToken");
    var cntoken = IS_MULTI_TAB_SUPPORT ? sessionStorage.getItem("token") : localStorage.getItem("token");
    var recoId = new URLSearchParams(url.search).get("recoId");
    var recoID = IS_MULTI_TAB_SUPPORT ? sessionStorage.getItem("recoId") : localStorage.getItem("recoId");
    var redirect = new URLSearchParams(url.search).get("redirect");

    if (recoId !== null) {
      IS_MULTI_TAB_SUPPORT ? sessionStorage.setItem("recoId", recoId) : localStorage.setItem("recoId", recoId);
      dispatch(getRecoRecordById(recoId)).then((res) => {
        if (res?.uploadScreenRedirect === true) {
          navigate(`/${COMPANY_STATEMENT}`);
          dispatch(setActiveTab(2));
          dispatch(setRecoIdofRow(recoId));
          dispatch(setRequestType(res.requestType));
        } else if (res.previewScreenRedirect === true) {
          navigate(`/${COMPANY_STATEMENT}`);
          dispatch(setActiveTab(2));
          dispatch(setRecoIdofRow(recoId));
          dispatch(setRequestType(res.requestType));
        } else {
          dispatch(setActiveValue("2"));
          dispatch(setModule("Track Reconciliation"));
          dispatch(setRecoName(res.payload.recoName));
          dispatch(setPartyCode(res.payload.recoName));
          dispatch(setRecoId(recoId));
          dispatch(getCountOfTableRecords({ recoId }));
          navigate(`/${REQUEST_STATEMENT}/${REQUEST_STATEMENT_MAIN}`);
        }
      });
      return;
    }

    if (token !== null) {
      dispatch(setIsTokenExpire(false));
      sessionStorage.clear();
      localStorage.clear();
      localStorage.setItem("tempToken", `Bearer ${token}`);
      IS_MULTI_TAB_SUPPORT ? sessionStorage.setItem("token", `Bearer ${token}`) : localStorage.setItem("token", `Bearer ${token}`);
      localStorage.setItem("accessToken", `Bearer ${token}`);
      IS_MULTI_TAB_SUPPORT ? sessionStorage.removeItem("partyToken") : localStorage.removeItem("partyToken");
      axiosApi.defaults.headers.common["Authorization"] = localStorage.getItem("tempToken");

      if (redirect === "confirmation") {
        localStorage.removeItem("tempToken");
        dispatch(reset());
        IS_MULTI_TAB_SUPPORT ? sessionStorage.removeItem("persist:root") : localStorage.removeItem("persist:root");
        navigate(`/${CONFIRMATION}`, { replace: true });
        return;
      }

      if (recoID !== null) {
        dispatch(getRecoRecordById(recoID)).then((res) => {
          if (res?.uploadScreenRedirect === true) {
            navigate(`/${COMPANY_STATEMENT}`);
            dispatch(setActiveTab(2));
            dispatch(setRecoIdofRow(recoID));
            dispatch(setRequestType(res.requestType));
          } else if (res.previewScreenRedirect === true) {
            navigate(`/${COMPANY_STATEMENT}`);
            dispatch(setActiveTab(2));
            dispatch(setRecoIdofRow(recoID));
            dispatch(setRequestType(res.requestType));
          } else {
            dispatch(setActiveValue("2"));
            dispatch(setModule("Track Reconciliation"));
            dispatch(setRecoName(res.recoName));
            dispatch(setPartyCode(res.recoName));
            dispatch(setRecoId(recoID));
            dispatch(getCountOfTableRecords({ recoID }));
            IS_MULTI_TAB_SUPPORT ? sessionStorage.removeItem("recoId") : localStorage.removeItem("recoId");
            navigate(`/${REQUEST_STATEMENT}/${REQUEST_STATEMENT_MAIN}`);
          }
        });
        return;
      }
      navigate(`/${REQUEST_STATEMENT}`);
      return;
    }

    if (ptoken !== null) {
      sessionStorage.clear();
      localStorage.clear();
      IS_MULTI_TAB_SUPPORT ? sessionStorage.setItem("partyToken", `Bearer ${ptoken}`) : localStorage.setItem("partyToken", `Bearer ${ptoken}`);
      IS_MULTI_TAB_SUPPORT ? sessionStorage.setItem("token", `Bearer ${ptoken}`) : localStorage.setItem("token", `Bearer ${ptoken}`);
      localStorage.removeItem("tempToken");
      localStorage.removeItem("accessToken");
      axiosPartyApi.defaults.headers.common["Authorization"] = IS_MULTI_TAB_SUPPORT ? sessionStorage.getItem("partyToken") : localStorage.getItem("partyToken");
      dispatch(getAllCommentsParty());
      navigate(`/${PARTY_RECO_SUMMARY}`, { replace: true });
      return;
    }

    if (partyToken !== null) {
      navigate(`/${PARTY_RECO_SUMMARY}`, { replace: true });
      return;
    }

    if (token === null && temptoken === null && cntoken === null) {
      window.location = v1URL;
    }

    // Remove trailing slash from the current URL
    const currentUrl = window.location.href.replace(/\/$/, '');
    const excludedRoutes = [
      `${v2URL}/${PARTY_MASTER_DATA}`,
      `${v2URL}/${COMPANY_STATEMENT}`,
      `${v2URL}/${DIRECT_RECO_TABLE}`,
      `${v2URL}/${REQUEST_STATEMENT}`,
      `${v2URL}/${AUTOMATION}/${SCHEDULER}`,
      `${v2URL}/${RECO_SETTINGS}/${COMPANY_RECO_SETTINGS}`,
      `${v2URL}/${SFTP}`,
      `${v2URL}`,
    ];
    // Check if the current URL is excluded or if a token is present
    const isExcluded = excludedRoutes.includes(currentUrl);
    const hasToken = cntoken || sessionStorage.getItem('token');

    // Handle token synchronization for multi-tab support
    if (IS_MULTI_TAB_SUPPORT && isExcluded && !sessionStorage.getItem('token')) {
      const localToken = localStorage.getItem('accessToken');
      sessionStorage.setItem('token', localToken);
    }

    // Logout from other tabs
    if (IS_MULTI_TAB_SUPPORT && !localStorage.getItem('accessToken')) {
      window.location = v1URL;
    }

    // If there's no token and the URL isn't excluded, and it's not the base v2 URL
    if (IS_MULTI_TAB_SUPPORT && !hasToken && !isExcluded && currentUrl !== v2URL) {
      navigate('/page-not-found');
    }

    // Set Mini Sidebar false
    if (!IS_MULTI_TAB_SUPPORT) {
      dispatch(setIsMiniSidebar(false));
    }

    axiosApi.defaults.headers.common["Authorization"] =
      localStorage.getItem("tempToken");
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setIsTokenExpire(false);
    }
  };

  const handleLogIn = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location = process.env.REACT_APP_V1_URL;
  };

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    "&.notistack-MuiContent-success": {
      backgroundColor: "#059E30",
      borderRadius: "10px",
    },
    "&.notistack-MuiContent-error": {
      backgroundColor: "#BF2727",
      borderRadius: "10px",
    },
    "&.notistack-MuiContent-info": {
      backgroundColor: "#0469E3",
      borderRadius: "10px",
    },
  }));

  const useStyles = makeStyles({
    cookieAlert: {
      "& .MuiAlert-icon": {
        display: "none",
      },
    },
  });

  const classes = useStyles();

  const CustomAlert = styled(Alert)`
    border-radius: 30px;
  `;
  const notistackRef = useRef();
  return (
    <>
      <StylesProvider jss={jss}>
        <SnackbarProvider
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          Components={{
            success: StyledMaterialDesignContent,
            error: StyledMaterialDesignContent,
            info: StyledMaterialDesignContent,
          }}
          ref={notistackRef}
          action={(key) => (
            <Button
              onClick={() => notistackRef.current.closeSnackbar(key)}
              style={{ color: "#fff", fontSize: "14px" }}
            >
              ✖
            </Button>
          )}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider theme={createTheme(theme)}>
                <ThemeProvider theme={createTheme(theme)}>
                  {content}
                </ThemeProvider>
              </MuiThemeProvider>
            </StyledEngineProvider>
          </LocalizationProvider>
        </SnackbarProvider>
      </StylesProvider>
      <Modal
        open={isTokenExpire}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={1}
          >
            <ClockedIcon />
            <Typography fontFamily={"fontsfree"} fontSize={"24px"}>
              Session Timed-out.
            </Typography>
            <Typography fontFamily={"SFProdisplay"} fontSize={"16px"}>
              Your session has timed out. Please log in again.{" "}
            </Typography>
            <Box
              bgcolor={"#005724"}
              color={"#FFF"}
              sx={{ cursor: "pointer" }}
              px={4}
              py={0.5}
              borderRadius={2}
              onClick={handleLogIn}
            >
              <Typography fontFamily={"fontsfree"}>Login</Typography>
            </Box>
          </Stack>
        </Box>
      </Modal>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={3000}
        onClose={() => dispatch(setErrorSnackbar(false))}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <CustomAlert
          onClose={() => dispatch(setErrorSnackbar(false))}
          className={classes.cookieAlert}
          sx={{
            width: "100%",
            bgcolor: "#BF2727",
            color: "white",
            boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.4)",
            fontWeight: "300",
            fontSize: "20px",
          }}
        >
          <Box display={"flex"} flexDirection={"row"} gap={2}>
            <WhiteCross />
            <Typography fontFamily={"fontsfree"} fontSize={"12px"}>
              {errorMsg}
            </Typography>
          </Box>
        </CustomAlert>
      </Snackbar>
    </>
  );
}

export default App;
