import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosApi from "../../helper/axiosApi";
import state from "./state";

export const getWorkflowSttingTypes = createAsyncThunk(
  "getWorkflowSttingTypes",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/settings/api/v1/settings/workflow`);
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const workFlowSettingSlice = createSlice({
  name: "workFlowSettingSlice",
  initialState: state,
  extraReducers: {
    [getWorkflowSttingTypes.pending]: (state, action) => {
      state.status = "loading";
      state.type = "WORKFLOW_SETTING_TYPES";
    },
    [getWorkflowSttingTypes.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "WORKFLOW_SETTING_TYPES";
    },
    [getWorkflowSttingTypes.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "WORKFLOW_SETTING_TYPES";
      state.error = action.payload;
    },
  },
  reducers: {
    setSttings(state, action) {
      state.settings = action.payload;
    },
  },
});

export const { setSttings } = workFlowSettingSlice.actions;

const { reducer } = workFlowSettingSlice;

export default reducer;
