import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CloseIcon } from "components/AllSvg";
import {
  Alert,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  TextareaAutosize,
} from "@mui/material";
import "assets/styles/reviewdone.css";
import { useDispatch, useSelector } from "react-redux";
import { requestSignOff, setRefresh } from "store/requeststatement/slice";
import makeStyles from "@mui/styles/makeStyles";

const style = {
  width: "90vw",
  maxWidth: "650px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "15px",
  padding: "24px 24px 17px 24px",
};

const submit = {
  width: "100px",
  height: "30px",
  fontSize: "12px",
  fontWeight: 700,
  background: "#005724",
  borderRadius: "5px",
  color: "#ffffff",
};

export default function RequestSignoff(props) {
  const [openSnack, setOpenSnack] = React.useState(false);
  const [workflowOption, setWorkflowOption] = React.useState("");
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const useStyles = makeStyles(() => ({
    selected: {
      color: "#FFFFFF",
      background: "#005724",
    },
    root: {
      color: "#005724",
      background: "#FFFFFF",
      border: "1px solid #005724",
      borderRadius: "4px",
    },
    cookieAlert: {
      "& .MuiAlert-icon": {
        color: "#ffffff",
      },
    },
  }));
  const classes = useStyles();

  const [comment, setComment] = React.useState("");

  const { selected, recoId, refresh, requestSignOffOption } = useSelector(
    (state) => state.trackreconciliation
  );
  const dispatch = useDispatch();
  const handleClose = () => {
    setComment("");
    props.setOpen(false);
  };
  const handleSubmit = () => {
    dispatch(
      requestSignOff({
        recoId,
        partyIds: selected.length === 0 ? [props.partyId] : selected,
        comment,
        isAllSelected: props.isAllSelected,
        workflowOption:
          workflowOption === ""
            ? requestSignOffOption?.[0]?.id
            : workflowOption,
      })
    ).then(() => {
      setOpenSnack(true);
      props.setRefresh(!props.refresh);
      dispatch(setRefresh(!refresh));
      handleClose();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ dispaly: "inline" }}
      >
        <Box sx={style}>
          <Stack direction="row" justifyContent="space-between">
            <Typography className="popuptitle">Request Sign off</Typography>
            <IconButton onClick={handleClose} disableRipple>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Typography className="popupsubtitle">
            Action will be taken only for parties with applicable statuses.
          </Typography>
          <Typography mb={"6px"}>
            Share Option<span style={{ color: "red" }}>*</span>
          </Typography>
          <Stack alignItems={"center"}>
            <FormControl
              fullWidth
              sx={{
                border: "0.5px solid #BBB",
                borderRadius: "5px",
                marginBlock: "10px",
                paddingLeft: "10px",
                mr: "10px",
                bgcolor: "#FFF",
              }}
            >
              <RadioGroup
                column
                defaultValue={requestSignOffOption?.[0]?.id ?? ""}
                onChange={(event) => {
                  if (requestSignOffOption?.length === 2) {
                    setWorkflowOption(event.target.value);
                  }
                }}
              >
                {requestSignOffOption?.map((item) => {
                  return (
                    <>
                      <FormControlLabel
                        value={item.id}
                        control={<Radio color="success" size="small" />}
                        label={item.value}
                      />
                      <FormHelperText
                        style={{
                          color: "#BBBBBB",
                          marginLeft: "24px",
                          marginTop: "-10px",
                        }}
                      >
                        {item.id === "entire_reconciliation_report"
                          ? "This will share all the sheets, including the summary, reconciliation, annexures, and party statement."
                          : "This will only share the party statement along with status."}
                      </FormHelperText>
                    </>
                  );
                })}
                {/* <FormControlLabel
                  value="double"
                  control={<Radio color="success" size="small" />}
                  label="Entire Reconciliation Report"
                />
                <FormHelperText
                  style={{
                    color: "#BBBBBB",
                    marginLeft: "24px",
                    marginTop: "-10px",
                  }}
                >
                  This will share all the sheets, including the summary,
                  reconciliation, annexures, and party statement.
                </FormHelperText> */}
                {/* <FormControlLabel
                  value="single"
                  control={<Radio color="success" size="small" />}
                  label="Party Details Only"
                />
              </RadioGroup>
              <FormHelperText
                style={{
                  color: "#BBBBBB",
                  marginLeft: "24px",
                  marginTop: "-10px",
                }}
              >
                This will only share the party statement along with status.
              </FormHelperText> */}
              </RadioGroup>
            </FormControl>
          </Stack>
          <Typography>Comment (if any)</Typography>
          <TextareaAutosize
            minRows={1}
            style={{
              width: "90vw",
              maxWidth: "590px",
              border: "1px solid #BBBBBB",
              borderRadius: "5px",
              padding: "8px",
              fontFamily: "SFProdisplay",
            }}
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            id="comment"
            variant="outlined"
            size="small"
            autoComplete="off"
            name="comment"
          />
          <Stack
            spacing={2}
            direction="row"
            marginTop={2}
            justifyContent="flex-end"
          >
            <Button className="cancel" onClick={handleClose}>
              Cancel
            </Button>
            <Button style={submit} onClick={handleSubmit}>
              Submit
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          className={classes.cookieAlert}
          severity="success"
          sx={{
            width: "100%",
            bgcolor: "rgba(37, 172, 75, 1)",
            color: "white",
            boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.4)",
            fontWeight: "700",
          }}
        >
          Reco sent for request sign-off successfully
        </Alert>
      </Snackbar>
    </>
  );
}
