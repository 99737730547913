const initialValues = {
  error: null,
  type: null,
  report: null,
  partySummaryData: {},
  trackRecoPage: 1,
  partyCode: "",
  partyDataIds: [],
  queryData: {},
  allComments: [],
  recoId: "",
  partyId: "",
  UnmatchedData: [],
  code: "",
  selected: [],
  AllPartyData: [],
  partyIds: [],
  companyDataIds: [],
  sumofRecords: "",
  viewremarksdata: {},
  disagreeCount: 0,
  recoDetails: {},
  summaryPdf: "",
  partyName: "",
  companyName: "",
  sort: { id: "", direction: "ascending" },
  dataIdsForAddRemark: [],
  reportData: {},
};

export default initialValues;
