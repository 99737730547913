import React, { useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "components/AllSvg";
import "assets/styles/matched.css";
import { useDispatch } from "react-redux";
import {
  setSelected,
  setPartyDataIds,
  setCompanyDataIds,
  setIsMiniSidebar,
} from "../../../store/requeststatement/slice";
import ActionViewTrackerTable from "./ActionViewTrackerTable";
import { REQUEST_STATEMENT, REQUEST_STATEMENT_MAIN } from "utils/constant";

const ActionTrackerMainCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //handle back arrow click
  const handleBackArrow = () => {
    dispatch(setSelected([]));
    dispatch(setPartyDataIds([]));
    dispatch(setCompanyDataIds([]));
    navigate(`/${REQUEST_STATEMENT}/${REQUEST_STATEMENT_MAIN}`);
  };

  /**
   * @purpose : Minimize the left side bar and maximize the left side bar on component unmount
   */
  useEffect(() => {
    dispatch(setIsMiniSidebar(true));
    return () => {
      dispatch(setIsMiniSidebar(false));
    };
  }, [dispatch]);

  return (
    <Box mt={"-40px"}>
      <Box sx={{ display: "flex", marginTop: "4px" }}>
        <ArrowLeft style={{ cursor: "pointer" }} onClick={handleBackArrow} />
        <Typography
          className="matchedHead"
          onClick={() => {
            dispatch(setIsMiniSidebar(true));
          }}
        >
          Action Tracker
        </Typography>
      </Box>
      <ActionViewTrackerTable />
    </Box>
  );
};
export default ActionTrackerMainCard;
