const initialValues = {
  // basic status
  status: null,
  error: null,
  type: null,

  configureData: null,
  recoId: null,
  partyId: null,
  isLoading: false,

  mappingData: [],
  otherData: [],
  validateData: null,
  removedData: null,
  loadData: null,
  summary: null,
  selectedFile: [],
  recoData: null,

  // modals
  openFormat: false,
  openMapping: false,
  openOtherMapping: false,
  openVerify: false,
  openGood: false,
  openModal: false,

  // statement
  statement: "",
  statementType: "",
  companyArr: [],
  partyArr: [],
  companyLedgerArr: [],
  partyLedgerArr: [],
  otherCompany: [],
  otherParty: [],
  otherId: null,
  recoStatus: null,

  confirmclear: null,
  directTableData: [],
  viewClickData: [],
  sort: { id: "insertedDate", direction: "descending" },

  displaySettings: [],
  refresh: false,
  fromTrackReco: false,
  fromDirectkReco: false,
  mappingStatus: false,
  rejectFromUpload: false,
  isLoadingFile: false,
  partyNameData: [],
  selectedPartyName: [],
  editableRecoData: {},
  recoIdforEdit: null,
  partyIdforEdit: null,
  selectRecordsData: [],
  selectedRecoPeriod: [],
  settingResponse: [],
  partyNameForDisplay: "",
  partyNameForEditDisplay: "",
  partyDocList: [],
  uploadModalTitle: "",
  invoiceTrackerArr: [],
};

export default initialValues;
