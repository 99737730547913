import React, { useState } from "react";
import {
  Alert,
  Button,
  Menu,
  MenuItem,
  Snackbar,
  Typography,
} from "@mui/material";
import {
  BulkAction,
  CloseIcon,
  AddViewRemarkIcon,
  AssignActionOwnerIcon,
} from "components/AllSvg";
import "assets/styles/menu.css";
import { makeStyles } from "@mui/styles";
import ActionTrackerAddRemarkModal from "./ModalComponents/ActionTrackerAddRemarkModal";
import ActionTrackerActionOwnerModal from "./ModalComponents/ActionTrackerActionOwnerModal";
const useStyles = makeStyles({
  cookieAlert: {
    "& .MuiAlert-icon": {
      color: "#ffffff",
    },
  },
});

const buttonSX = {
  marginRight: "12px",
  backgroundColor: "transparent",
  color: "#005724",
};

const ActionBulkActions = ({
  isItemSelected,
  refresh,
  setRefresh,
  setIsItemSelected,
  selectAll,
  setIsAllSelected,
}) => {
  //Menu items control

  const [anchorEl, setAnchorEl] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openActionTrackerAddRemark, setopenActionTrackerAddRemark] =
    useState(false); //for add remark popup
  const [actionTrackerActionOwner, setactionTrackerActionOwner] =
    useState(false); //for action owner popup

  //open snackbar
  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
  };

  //close Snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClearMenuClick = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //add remark modal
  const handleAddRemark = () => {
    setopenActionTrackerAddRemark(true);
  };

  const handleAssignActionOwner = () => {
    setactionTrackerActionOwner(true);
  };

  const open = Boolean(anchorEl);
  const classes = useStyles();

  return (
    <>
      <Button
        aria-controls={open ? "menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        onClick={handleMenuClick}
        startIcon={<BulkAction />}
        style={buttonSX}
      >
        <Typography fontWeight={700}>Bulk Actions</Typography>
      </Button>
      <Menu
        id="menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          style: {
            boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 15px",
          },
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        sx={{ marginTop: "8px", marginLeft: "-5px" }}
      >
        <MenuItem className="card_heading">
          <Typography className="menu-title">Bulk Actions</Typography>
          <CloseIcon onClick={handleClearMenuClick} />
        </MenuItem>

        <MenuItem
          onClick={
            isItemSelected ? handleAssignActionOwner : handleClickSnackbar
          }
        >
          <AssignActionOwnerIcon fill={"#005724"} />
          <Typography fontFamily="fontsfree" marginLeft={2}>
            Assign/Update Action Owner
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={isItemSelected ? handleAddRemark : handleClickSnackbar}
        >
          <AddViewRemarkIcon fill={"#005724"} />
          <Typography fontFamily="fontsfree" marginLeft={2}>
            Add Remark
          </Typography>
        </MenuItem>
      </Menu>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          className={classes.cookieAlert}
          severity="info"
          sx={{
            width: "100%",
            bgcolor: "rgba(37, 172, 75, 1)",
            color: "white",
            boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.4)",
            fontWeight: "700",
          }}
        >
          Please select atleast one record
        </Alert>
      </Snackbar>

      <ActionTrackerAddRemarkModal
        openActionTrackerAddRemark={openActionTrackerAddRemark}
        setopenActionTrackerAddRemark={setopenActionTrackerAddRemark}
        refresh={refresh}
        setRefresh={setRefresh}
        setIsItemSelected={setIsItemSelected}
        selectAll={selectAll}
        setIsAllSelected={setIsAllSelected}
      />
      <ActionTrackerActionOwnerModal
        actionTrackerActionOwner={actionTrackerActionOwner}
        setactionTrackerActionOwner={setactionTrackerActionOwner}
        refresh={refresh}
        setRefresh={setRefresh}
        setIsItemSelected={setIsItemSelected}
        selectAll={selectAll}
        setIsAllSelected={setIsAllSelected}
      />
    </>
  );
};

export default ActionBulkActions;
